import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { HttpClientService } from '../services/http-client.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { PageRouterService } from '../services/page-router.service';
import { FirebaseService } from '../services/firebase.service';

interface HourObject {
  hours: number;
  minutes: number;
  string: string;
  scheduled: boolean;
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
  title = 'JC Ingeniería';
  ableToSchedule = true;
  ableToGivePhone = true;
  jobCategory: string = "";
  actualDate = new Date();
  selectedDate = new Date();
  scheduledCalls: Array<any> = [];
  phoneNumbers: Array<any> = [];
  clientIP: string = "";
  callHourSelected: HourObject | undefined = undefined;
  phoneNumbersObservable: Observable<any[]> | undefined;
  hoursObjectArray: Array<HourObject> = [{hours: 9, minutes: 0, string: '09:00', scheduled: false},
    {hours: 11, minutes: 0, string: '11:00', scheduled: false},
    {hours: 15, minutes: 0, string: '15:00', scheduled: false}];
  instagramIcon = faInstagram;
  facebookIcon = faFacebookF;
  scheduledCallsObservable: Observable<any[]> | undefined;
  scheduleFormGroup: FormGroup = new FormGroup({
    clientName: new FormControl(undefined),
    clientEmail: new FormControl(undefined, [Validators.email, Validators.required]),
    clientPhone: new FormControl(undefined, Validators.pattern('^[\\+][5][6][9][\\s]?[0-9]{4}[\\s]?[0-9]{4}$')),
    jobDescription: new FormControl(undefined),
    clientEnterpriseOrPerson: new FormControl('Persona')
  });
  phoneNumberFormControl = new FormControl(undefined, [Validators.pattern('^[\\+][5][6][9][\\s]?[0-9]{4}[\\s]?[0-9]{4}$'), Validators.required]);
  innerWidth: number = window.innerWidth;
  sidebarIsOpen = false;
  ipSubscription: any;
  scheduleCallSubscription: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  constructor(private http: HttpClientService, private firebaseService: FirebaseService, private pageRouter: PageRouterService) {
    this.pageRouter.currentPage('/home');
    this.phoneNumbersObservable = firebaseService.getCollectionObservable('phoneNumbers');
    this.scheduledCallsObservable = firebaseService.getCollectionObservable('scheduledCalls');
  }

  async ngOnInit(): Promise<void> {
    this.ipSubscription = this.getIPInfo().subscribe({
      next: (res: any) => {
        this.clientIP = res.ip;
      }
    });
    this.scheduleCallSubscription = this.scheduledCallsObservable?.subscribe({
      next: (scheduledCalls) => {
        this.scheduledCalls = scheduledCalls;
        this.selectedDateChange();
      }
    });
    this.phoneNumbersObservable?.subscribe({
      next: (phoneNumbers: any) => {
        this.phoneNumbers = phoneNumbers;
        for (let phoneNumber of phoneNumbers) {
          if (this.clientIP === phoneNumber.clientIP) {
            this.ableToGivePhone = false;
          }
        }
      }
    })
  }

  selectedDateChange(): void {
    this.hoursObjectArray = [{hours: 9, minutes: 0, string: '09:00', scheduled: false},
      {hours: 11, minutes: 0, string: '11:00', scheduled: false},
      {hours: 15, minutes: 0, string: '15:00', scheduled: false}];
    // se recorren las llamadas agendadas
    for (let scheduledCall of this.scheduledCalls) {
      if (scheduledCall.ip === this.clientIP) {
        this.ableToSchedule = false;
      }
      // Se obtiene la fecha agendada
      const tempDate = new Date(scheduledCall.date.seconds * 1000);
      // Se formatean las fechas para compararlas
      let selectedDateDatetime = DateTime.fromJSDate(this.selectedDate);
      selectedDateDatetime = selectedDateDatetime.setZone('local');
      let scheduledCallDatetime = DateTime.fromJSDate(tempDate);
      scheduledCallDatetime = scheduledCallDatetime.setZone('local');
      let actualDateDatetime = DateTime.local();
      // Se verifica que el dia de la fecha seleccionada sea la misma que el dia de la fecha agendada
      if (this.areSameDayOfTheYear(selectedDateDatetime, scheduledCallDatetime)) {
        // Se recorren las horas determinadas para las llamadas
        for (let availableHour of this.hoursObjectArray) {
          // En caso de que la hora agendada sea igual a la hora determinada, entonces se deja deshabilitada para la seleccion.
          if (availableHour.hours === scheduledCallDatetime.hour ||
              (this.areSameDayOfTheYear(actualDateDatetime, scheduledCallDatetime) && (actualDateDatetime.hour > availableHour.hours))) {
            availableHour.scheduled = true;
          }
        }
      }
    }
  }

  async makeCallScheduled() {
    const callDate = this.selectedDate;
    const scheduleCallsCollection = "scheduledCalls";
    if (this.callHourSelected !== undefined) {
      callDate.setHours(this.callHourSelected.hours, this.callHourSelected.minutes, 0, 0);
      this.firebaseService.addDocument(scheduleCallsCollection ,{
        ip: this.clientIP,
        email: this.scheduleFormGroup.get('clientEmail')?.value,
        clientType: this.scheduleFormGroup.get('clientEnterpriseOrPerson')?.value,
        jobType: this.jobCategory,
        jobDescription: this.scheduleFormGroup.get('jobDescription')?.value,
        name: this.scheduleFormGroup.get('clientName')?.value,
        phone: this.scheduleFormGroup.get('clientPhone')?.value,
        date: callDate,
      }).then(async (doc) => {
        this.ableToSchedule = false;
        await this.firebaseService.updateDocument(scheduleCallsCollection, doc.id, {
          uid: doc.id
        });
        Swal.fire({
          title: 'Llamada agendada',
          text: 'Te enviaremos la invitación al correo indicado',
          icon: 'success',
          confirmButtonText: 'Cerrar',
          customClass: 'position: fixed'
        })
      }).catch((error) => {
        this.firebaseService.addDocument("errors", error);
      });
    }
  }

  async givePhoneNumber() {
    const phoneNumbersCollection = "phoneNumbers";
    if (this.ableToGivePhone) {
      this.firebaseService.addDocument(phoneNumbersCollection, {
        phoneNumber: this.phoneNumberFormControl.value,
        clientIP: this.clientIP,
      }).then( async (doc) => {
        this.ableToSchedule = false;
        await this.firebaseService.updateDocument(phoneNumbersCollection, doc.id, {
          uid: doc.id
        });
        Swal.fire({
          title: 'Número enviado',
          text: 'Te llamaremos lo mas pronto posible.',
          icon: 'success',
          confirmButtonText: 'Cerrar'
        })
      }).catch((error) => {
        Swal.fire({
          title: 'Ocurrio un error',
          text: 'Lo sentimos mucho, intentelo nuevamente mas tarde.',
          icon: 'error',
          confirmButtonText: 'Cerrar'
        })
        this.firebaseService.addDocument("errors", error);
      });
    } else {
      Swal.fire({
        title: 'Encontramos tu número',
        text: 'Te llamaremos lo mas pronto posible.',
        icon: 'info',
        confirmButtonText: 'Cerrar'
      })
    }
  }

  areSameDayOfTheYear(firstDate: DateTime, secondDate: DateTime): boolean {
    if (firstDate.hasSame(secondDate, 'day') &&
        firstDate.hasSame(secondDate, 'month') &&
        firstDate.hasSame(secondDate, 'year')) {
          return true;
        } else {
          return false;
        }
  }

  getIPInfo() {
    const ipObservable = this.http.get('https://ipapi.co/json/');
    return ipObservable;
  }

  ngOnDestroy(): void {
    if (this.ipSubscription) {
      this.ipSubscription.unsubscribe();
    }
    if (this.scheduleCallSubscription) {
      this.scheduleCallSubscription.unsubscribe();
    }
  }
}
