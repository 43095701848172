<div class="container-fluid">
  <mat-card>
    <mat-card-header class="mb-3">
      <mat-card-title class="text-center">
        Gestión de clientes
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="scheduleCalls.length === 0">
        No quedan solicitudes de agendas pendientes.
      </div>
      <div class="row">
        <div class="col-3" *ngFor="let call of scheduleCalls">
          <mat-card>
            <mat-card-header>
              <mat-card-title>
                {{call.name}}
              </mat-card-title>
              <mat-card-subtitle>
                <p>Correo: {{call.email}}</p>
                <p>Telefono: {{call.phone}} </p>
              </mat-card-subtitle>
              <mat-card-title>Descripción</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              {{call.jobDescription}}
            </mat-card-content>
            <mat-card-actions>
              <!-- TODO: Implement when the calls filter is functional <button mat-button (click)="markAsReaded(call)">Marcar como agendado</button> -->
              <button mat-button (click)="deleteScheduleCall(call.uid)">Eliminar</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
