
<!-- HOME -->
<div class="position-relative" id="Home">
  <img src="../assets/photos/landpage-1.jpg" class="responsive-img">
  <div class="first-section-block">
    <div class="first-section-block-text">
      <h1 *ngIf="innerWidth > 768" style="letter-spacing: 10px;"> <b>INGENIERÍA</b> </h1>
      <h1 *ngIf="innerWidth > 768" style="letter-spacing: 10px;"> ARQUITECTURA </h1>
      <h1 *ngIf="innerWidth > 768" style="letter-spacing: 10px;"> <b>CONSTRUCCIÓN</b> </h1>
      <h1 *ngIf="innerWidth > 768" class="m-0" style="letter-spacing: 10px;"> ASESORAMIENTOS </h1>
      <h3 *ngIf="innerWidth <= 768" style="letter-spacing: 10px;"> <b>INGENIERÍA</b> </h3>
      <h3 *ngIf="innerWidth <= 768" style="letter-spacing: 10px;"> ARQUITECTURA </h3>
      <h3 *ngIf="innerWidth <= 768" style="letter-spacing: 10px;"> <b>CONSTRUCCIÓN</b> </h3>
      <h3 *ngIf="innerWidth <= 768" class="m-0" style="letter-spacing: 10px;"> ASESORAMIENTOS </h3>
    </div>
  </div>
  <div class="first-section-right-block">
    <div class="my-3 ms-3 first-section-right-block-text">
      <h3> <b>AQUACHILE - PLANTA MAGALLANES</b> </h3>
    </div>
  </div>
</div>

<!-- US -->
<!-- OUR SERVICES -->
<div id="Us" style="width: 100%;"></div>
<div class="d-flex align-items-center justify-content-center responsive-section">
  <div class="info-section">
    <div class="row w-100">
      <div class="col-12">
        <div class="second-section-first-block">
          <h1 class="section-title"><b>NOSOTROS</b></h1>
          <p style="font-size: 16px; line-height: 18px;">
            SOMOS UNA EMPRESA FAMILIAR DEDICADA A LA INGENIERIA,
            CONSTRUCCIÓN, ARQUITECTURA Y ASESORAMIENTO DE INFRAESTRUCTURA
            DE GRANDES EMPRESAS, TRABAJAMOS TANTO PARA EL AMBITO PÚBLICO COMO
            EL PRIVADO, CONTAMOS CON UN EXCELENTE EQUIPO PROFESIONAL, CAPACITADOS
            Y ORIENTADOS AL BUEN CONSTRUIR.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="col second-section-second-block">
          <h2 class="section-subtitle">MISIÓN</h2>
          <p class="second-section-paragraph">ASESORAR A GRANDES EMPRESAS EN EL MANTENIMIENTO DE SU
            INFRAESTRUCTURA Y EN EL CUMPLIMIENTO NORMATIVO ACTUAL. ENTREGAR
            UN SERVICIO EFICIENTE A NUESTROS CLIENTES, A TRAVÉS DE NUESTRA
            EXPERIENCIA Y CONOCIMIENTO DE NUESTRO OFICIO.</p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="col second-section-second-block">
          <h2 class="section-subtitle">VISIÓN</h2>
          <p class="second-section-paragraph">LA MEJOR EXPERIENCIA EN DISEÑO, CONSTRUCCIÓN Y
            ASESORAMIENTO DE INFRAESTRUCTURA PARA EMPRESAS DE LA QUINTA
            REGIÓN</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="position-relative">
  <img src="../assets/photos/landpage-2.jpg" class="responsive-img" >
  <div class="first-section-right-block">
    <div class="my-3 ms-3 first-section-right-block-text">
      <h3> <b>AQUACHILE - PLANTA MAGALLANES</b> </h3>
    </div>
  </div>
</div>

<!-- OUR SERVICES -->
<div id="OurServices" style="width: 100%; height: 200px"></div>
<div  class="d-flex align-items-center justify-content-center"
  style="margin-bottom: 200px;">
  <div class="row">
    <div class="col-12">
      <div class="info-section mt-3">
        <div class="row third-section-first-block">
          <h1 class="section-title text-center">SERVICIOS</h1>
          <p class="text-justify">SOMOS UN EQUIPO DE PROFESIONALES EN EL ÁREA
            DE LA CONSTRUCCIÓN Y ARQUITECTURA, CON
            EXPERIENCIA Y DEDICACIÓN EN NUESTROS OFICIOS.

            CREEMOS EN EL BUEN CONSTRUIR, A TRAVÉS DE
            PROYECTOS EFICIENTES Y CONSTRUCCIONES
            SOSTENIBLES.</p>
        </div>
        <div class="row w-100">
          <div class="col-xl-3 col-lg-6 col-sm-12 col-12 third-section-second-block">
            <h2 class="section-subtitle">INGENIERÍA</h2>
            <mat-chip-listbox [ngClass]="{'mat-mdc-chip-set-stacked': true, 'w-50': innerWidth > 600, 'w-75': innerWidth <= 600}">
              <mat-chip-option selected disabled>
                CÁLCULO ESTRUCTURAL
              </mat-chip-option>
              <mat-chip-option selected disabled>
                GEOTÉCNIA
              </mat-chip-option>
              <mat-chip-option selected disabled>
                MECÁNICA DE SUELOS
              </mat-chip-option>
              <mat-chip-option selected disabled>
                TOPOGRAFÍAS
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
          <div class="col-xl-3 col-lg-6 col-sm-12 col-12 third-section-second-block">
            <h2 class="section-subtitle">CONSTRUCCIÓN</h2>
            <mat-chip-listbox [ngClass]="{'mat-mdc-chip-set-stacked': true, 'w-50': innerWidth > 600, 'w-75': innerWidth <= 600}">
              <mat-chip-option selected disabled>
                CONSTRUCCIÓN DE EDIFICACIONES
              </mat-chip-option>
              <mat-chip-option selected disabled>
                OBRAS CIVILES
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
          <div class="col-xl-3 col-lg-6 col-sm-12 col-12 third-section-third-block">
            <h2 class="section-subtitle">ARQUITECTURA</h2>
            <mat-chip-listbox [ngClass]="{'mat-mdc-chip-set-stacked': true, 'w-50': innerWidth > 600, 'w-75': innerWidth <= 600}">
              <mat-chip-option selected disabled>
                DISEÑOS RESIDENCIALES
              </mat-chip-option>
              <mat-chip-option selected disabled>
                EDIFICIOS PÚBLICOS
              </mat-chip-option>
              <mat-chip-option selected disabled>
                EDIFICIOS PRIVADOS
              </mat-chip-option>
              <mat-chip-option selected disabled>
                INFRAESTRUCTURA INDUSTRIAL
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
          <div class="col-xl-3 col-lg-6 col-sm-12 col-12 third-section-third-block">
            <h2 class="section-subtitle">ASESORAMIENTOS</h2>
            <mat-chip-listbox [ngClass]="{'mat-mdc-chip-set-stacked': true, 'w-50': innerWidth > 600, 'w-75': innerWidth <= 600}">
              <mat-chip-option selected disabled>
                EMPRESAS PÚBLICAS
              </mat-chip-option>
              <mat-chip-option selected disabled>
                EMPRESAS PRIVADAS
              </mat-chip-option>
              <mat-chip-option selected disabled>
                EMPRESAS INDUSTRIALES
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12  mt-3">
      <div class="info-section mt-3">
        <div class="row third-section-first-block">
          <div class="col-lg-12 col-sm-12 col-12 third-section-second-block">
            <h1 class="section-title text-center"> PROPUESTA DE ASESORAMIENTO </h1>
            <p class="text-justify">
              QUEREMOS SER UN APOYO TÉCNICO CONTINUO DE SU INFRAESTRUCTURA,
              IDENTIFICANDO EN TERRENO LAS MEJORAS NECESARIAS PARA QUE SU EMPRESA
              SE DESARROLLE SIN PROBLEMATICAS CONSTRUCTIVAS A CORTO PLAZO Y QUE
              GENEREN EN DEFINITIVA UN AHORRO EN COSTOS A LARGO PLAZO.

              MEJORAS COMUNMENTE IDENTIFICADAS:
            </p>
          </div>
          <div class="col-lg-12 col-sm-12 col-12 third-section-second-block">
            <h2 class="section-subtitle">NORMATIVOS</h2>
            <p class="text-justify">
              CUMPLIMIENTO DE EXIGENCIAS CONSTRUCTIVAS Y SANITARIAS
              EN RAZÓN DE LA ORDENANZA GENERAL DE URBANISMO Y CONSTRUCCIÓN.
            </p>
          </div>
          <div class="col-lg-12 col-sm-12 col-12 third-section-second-block">
            <h2 class="section-subtitle">CONSTRUCTIVAS</h2>
            <p class="text-justify">
              SOLICITUD DE MODIFICACIÓN DE RECINTOS POR NECESIDADES DE
              CRECIMIENTO Y/O MEJORA DE LA EMPRESA, SOLICITUD DE MEJORAS
              EXTERIORES, IDENTIFICACIÓN DE PROBLEMÁTICAS CONSTRUCTIVAS
              POR FALTA DE MANTENCIÓN (FILTRACIONES DE AGUA EN TECHUMBRES,
              FILTRACIONES EN CAÑERIAS, ENTRE OTROS)
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12  mt-3">
      <div class="info-section mt-3">
        <div class="row third-section-first-block">
          <div class="col-lg-12 col-sm-12 col-12 third-section-third-block">
            <h1 class="section-title text-center"> INSPECCIÓN INICIAL </h1>
          </div>
          <div class="col-lg-12 col-sm-12 col-12 third-section-second-block">
            <p class="text-justify">
                REVISIÓN DE PLANOS Y CONSTRUCCIÓN EXISTENTE PARA IDENTIFICAR
                TODOS LOS RECINTOS, EDIFICACIONES PERMANENTES Y CONSTRUCCIONES PROVISORIAS.
            </p>
          </div>
          <div class="col-lg-12 col-sm-12 col-12 third-section-third-block">
              <p class="text-justify">
                IDENTIFICACIÓN DE PUNTOS CRITICOS (NECESIDAD DE REPOSICIÓN Y
                ZONAS CON NECESIDAD CON POSIBILIDAD DE MANTENCIÓN.)
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="position-relative">
  <img src="../assets/photos/landpage-3.png" class="responsive-img">
  <div class="first-section-right-block">
    <div class="my-3 ms-3 first-section-right-block-text">
      <h3> <b>AQUACHILE - PLANTA MAGALLANES</b> </h3>
    </div>
  </div>
</div>


<!-- SCHEDULE CALL -->
<div id="ScheduleCall" *ngIf="innerWidth <= 768" style="width: 100%; height: 200px"></div>
<div id="ScheduleCall" *ngIf="innerWidth > 768" style="width: 100%;"></div>
<div [ngClass]="{'d-flex align-items-center justify-content-center': true, 'responsive-section': innerWidth > 768}">
  <div class="videocall-schedule-section mt-3">
    <div class="fourth-section-first-block">
      <h1 class="section-title">AGENDA UNA VIDEOLLAMADA</h1>
    </div>
    <div class="row w-100">
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 mb-3">
        <div class="row w-100">
          <h1 class="section-subtitle text-justify" style="margin-left: 10px">SELECCIONA TU SERVICIO</h1>
          <div class="col">
            <h3 class="radio-button-section-subtitle">INGENIERÍA</h3>
            <mat-radio-group
              class="radio-group"
              [(ngModel)]="jobCategory">
              <mat-radio-button class="radio-button-section" value="CÁLCULO ESTRUCTURAL">
                CÁLCULO ESTRUCTURAL
              </mat-radio-button>
              <mat-radio-button class="radio-button-section" value="GEOTÉCNIA">
                GEOTÉCNIA
              </mat-radio-button>
              <mat-radio-button class="radio-button-section" value="MECÁNICA DE SUELOS">
                MECÁNICA DE SUELOS
              </mat-radio-button>
              <mat-radio-button class="radio-button-section" value="TOPOGRAFÍAS">
                TOPOGRAFÍAS
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col">
            <h3 class="radio-button-section-subtitle">ARQUITECTURA</h3>
            <mat-radio-group
              class="radio-group"
              [(ngModel)]="jobCategory">
              <mat-radio-button class="radio-button-section" value="DISEÑOS RESIDENCIALES">
                DISEÑOS RESIDENCIALES
              </mat-radio-button>
              <mat-radio-button class="radio-button-section" value="EDIFICIOS PÚBLICOS/PRIVADOS">
                EDIFICIOS PÚBLICOS/PRIVADOS
              </mat-radio-button>
              <mat-radio-button class="radio-button-section" value="INFRAESTRUCTURA INDUSTRIAL">
                INFRAESTRUCTURA INDUSTRIAL
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col">
            <h3 class="radio-button-section-subtitle">ASESORAMIENTOS</h3>
            <mat-radio-group
            class="radio-group"
              [(ngModel)]="jobCategory">
              <mat-radio-button class="radio-button-section" value="EMPRESAS PÚBLICAS/PRIVADAS">
                EMPRESAS PÚBLICAS/PRIVADAS
              </mat-radio-button>
              <mat-radio-button class="radio-button-section" value="INDUSTRIALES">
                INDUSTRIALES
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 d-flex flex-column align-items-center mb-3">
          <h1 class="section-subtitle text-justify"> SELECCIONE UNA FECHA </h1>
          <mat-card class="calendar-card">
            <mat-calendar [minDate]="actualDate" [(selected)]="selectedDate" (selectedChange)="selectedDateChange()"></mat-calendar>
          </mat-card>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 d-flex align-items-center flex-column mb-3">
        <h1 class="section-subtitle text-justify"> SELECCIONE UN HORARIO </h1>
        <mat-chip-listbox class="mat-mdc-chip-set-stacked w-25 text-center" [(ngModel)]="callHourSelected">
          <mat-chip-option *ngFor="let hourObject of hoursObjectArray" [disabled]="hourObject.scheduled" [value]="hourObject">{{hourObject.string}}</mat-chip-option>
        </mat-chip-listbox>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 mb-3">
        <h1 class="section-subtitle text-justify"> INGRESE INFORMACIÓN </h1>
        <form [formGroup]="scheduleFormGroup">
          <mat-radio-group aria-label="Persona natural o Empresa" formControlName="clientEnterpriseOrPerson">
            <mat-radio-button value="Persona">Persona natural</mat-radio-button>
            <mat-radio-button value="Empresa">Empresa</mat-radio-button>
          </mat-radio-group>
          <mat-form-field class="w-100">
            <mat-label>Nombre persona/empresa</mat-label>
            <input matInput formControlName="clientName">
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Correo</mat-label>
            <input matInput type="email" formControlName="clientEmail" placeholder="Ej. correo@electronico.com">
            <mat-error *ngIf="scheduleFormGroup.get('clientEmail')?.hasError('email') && !scheduleFormGroup.get('clientEmail')?.hasError('required')">
              Ingrese un correo valido.
            </mat-error>
            <mat-error *ngIf="scheduleFormGroup.get('clientEmail')?.hasError('required')">
              El correo es <strong>necesario.</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Teléfono</mat-label>
            <input matInput formControlName="clientPhone" maxlength="12" placeholder="Ej. +56912345678">
            <mat-error *ngIf="scheduleFormGroup.get('clientPhone')?.hasError('pattern')">
              Ingrese su telefono con formato +56911112222
            </mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Resumen del trabajo</mat-label>
            <textarea matInput formControlName="jobDescription"></textarea>
          </mat-form-field>
        </form>
        <div class="w-100 text-center mb-3">
          <button mat-raised-button class="mt-2" (click)="makeCallScheduled()"
            [disabled]="!ableToSchedule || !jobCategory || !callHourSelected || scheduleFormGroup.invalid || scheduleFormGroup.get('clientEnterpriseName')?.value === undefined">
            Agendar llamada
          </button>
          <mat-error *ngIf="!ableToSchedule">
            Ya tienes una llamada agendada.
          </mat-error>
          <mat-error *ngIf="!jobCategory && ableToSchedule">
            Selecciona el tipo de trabajo deseado.
          </mat-error>
          <mat-error *ngIf="!callHourSelected && ableToSchedule">
            Selecciona una hora disponible para la llamada.
          </mat-error>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="position-relative">
  <img src="../assets/photos/landpage-4.png" class="responsive-img">
  <div class="first-section-right-block">
    <div class="my-3 ms-3 first-section-right-block-text">
      <h3> <b>AQUACHILE - PLANTA MAGALLANES</b> </h3>
    </div>
  </div>
</div>

<!-- CONTACT US -->
<div id="ContactUs">
  <div class="fifth-section-first-block mb-3">
    <h1 class="section-title text-justify">CONTACTATE CON NOSOTROS</h1>
  </div>
  <div class="row contact-section">
    <div class="col-lg-4 col-12 order-lg-first order-last d-flex justify-content-center align-items-center">
      <fa-icon [icon]="facebookIcon" class="facebook-logo d-none"></fa-icon>
      <fa-icon [icon]="instagramIcon" class="instagram-logo d-none"></fa-icon>
    </div>
    <div class="col-lg-4 col-12 section-subtitle d-flex flex-column align-items-center justify-content-center" style="font-size: 18px;">
      <p *ngIf="innerWidth >= 600">CORREO: ECARCAMO.JCINGENIERIA@GMAIL.COM</p>
      <p *ngIf="innerWidth >= 600">TELÉFONO: +56 9 9327 0465</p>
      <p *ngIf="innerWidth < 600">CORREO</p>
      <p *ngIf="innerWidth < 600">ECARCAMO.JCINGENIERIA@GMAIL.COM</p>
      <p *ngIf="innerWidth < 600">TELÉFONO</p>
      <p *ngIf="innerWidth < 600">+56 9 9327 0465</p>
    </div>
    <div class="col-lg-4 col-12 section-subtitle text-center" style="font-size: 18px">
      <form>
        <p>DÉJANOS TU NÚMERO Y NOS COMUNICAREMOS CONTIGO</p>
        <mat-form-field class="mt-3">
          <mat-label>Teléfono</mat-label>
          <input matInput maxlength="12" [formControl]="phoneNumberFormControl" placeholder="Ej. +569 1234 5678">
          <mat-error *ngIf="phoneNumberFormControl.hasError('pattern')">
            Ingrese su telefono con formato +569 1234 5678
          </mat-error>
          <mat-error *ngIf="phoneNumberFormControl.hasError('required')">
            Ingrese su número de telefono
          </mat-error>
        </mat-form-field>
        <button mat-raised-button class="mb-3 ms-3 contact-button" [disabled]="phoneNumberFormControl.invalid" (click)="givePhoneNumber()">ENVIAR</button>
      </form>
    </div>
  </div>
</div>

<app-footer class="py-3"></app-footer>
