import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class PageRouterService {
  private page:Subject<string> = new BehaviorSubject<string>('/');

  get pageObservable(){
    return this.page.asObservable();
  }

  currentPage(page:string) {
    this.page.next(page);
  }
}
