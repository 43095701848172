
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

/** Adapts the native JS Date for use with cdk-based components that work with dates. */
@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
  constructor() {
    super('es-CL');
  }
  // parse the date from input component as it only expect dates in
  // mm-dd-yyyy format
  override parse(value: any): Date | null {
    if (typeof value === 'string' && value.indexOf('/') > -1) {
      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }
}
