import { Component } from '@angular/core';
import { PageRouterService } from '../services/page-router.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent {
  sidebarIsOpen = false;
  constructor(private pageRouter: PageRouterService) {
    this.pageRouter.currentPage('/admin');
  }
}
