import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageRouterService } from '../services/page-router.service';
import { FirebaseService } from '../services/firebase.service';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-clients-page',
  templateUrl: './clients-page.component.html',
  styleUrls: ['./clients-page.component.scss']
})
export class ClientsPageComponent implements OnInit, OnDestroy {
  scheduleCallsObservable: Observable<any>;
  scheduleCallsSubscription: Subscription | undefined;
  scheduleCalls: any[] = [];
  constructor(private pageRouter: PageRouterService, private firebaseService: FirebaseService) {
    this.pageRouter.currentPage('/clients');
    this.scheduleCallsObservable = firebaseService.getCollectionObservable('scheduledCalls');
  }

  ngOnInit(): void {
    this.scheduleCallsSubscription = this.scheduleCallsObservable.subscribe({
      next: (scheduleCalls) => {
        console.log(scheduleCalls);
        this.scheduleCalls = scheduleCalls;
      }, error: (err) => {
        console.error(err);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.scheduleCallsSubscription) {
      this.scheduleCallsSubscription.unsubscribe();
    }
  }

  deleteScheduleCall(uid: string) {
    console.log(uid);
    Swal.fire({
      title: '¿ Esta seguro de eliminar esta agenda ? ',
      showDenyButton: true,
      icon: 'warning',
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.firebaseService.deleteDocument("scheduledCalls", uid).then(() => {
          Swal.fire({
            title: 'Agenda Eliminada',
            icon: 'success',
            confirmButtonText: 'Cerrar',
            customClass: 'position: fixed'
          })
        }).catch((err) => {
          console.error(err);
        })
      }
    })
  }

  /* TODO: Implement when the calls filter is functional
  async markAsReaded(call: any) {
    if (call.readed) {
      this.firebaseService.updateDocument("scheduledCalls", call.uid, {
        readed: true
      });
    } else {
      this.firebaseService.updateDocument("scheduledCalls", call.uid, {
        readed: false
      });
    }
  } */
}
