import { Component } from '@angular/core';
import { PageRouterService } from '../services/page-router.service';

@Component({
  selector: 'app-management-page',
  templateUrl: './management-page.component.html',
  styleUrls: ['./management-page.component.scss']
})
export class ManagementPageComponent {
  constructor(private pageRouter: PageRouterService) {
    this.pageRouter.currentPage('/management');
  }
}
