export const environment = {
  firebase: {
    apiKey: "AIzaSyC0_qqX7p_Ipd5C7P7_7QjyRvkcPqLyP64",
    authDomain: "jc-ingenieria-dev.firebaseapp.com",
    projectId: "jc-ingenieria-dev",
    storageBucket: "jc-ingenieria-dev.appspot.com",
    messagingSenderId: "404350817847",
    appId: "1:404350817847:web:f827be13da6a586730d1b2",
    measurementId: "G-VWG42DQMVM"
  },
    production: false
}
