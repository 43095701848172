import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollService } from './services/scroll.service';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { PageRouterService } from './services/page-router.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  currentPage: string = '/';
  sidebarIsOpen = false;
  instagramIcon = faInstagram;
  constructor(private router: Router, private route: ActivatedRoute, private scroll: ScrollService, private pageService: PageRouterService) {}

  ngOnInit(): void {
    this.pageService.pageObservable.subscribe((page: string) => {
      this.currentPage = page;
    })
  }

  scrollToHome() {
    this.scroll.scrollToElementById('Home');
    setTimeout(() => {
      if (this.sidebarIsOpen) {
        this.sidebarIsOpen = false;
      }
    }, 1000);
  }

  scrollToUs() {
    this.scroll.scrollToElementById('Us');
    setTimeout(() => {
      if (this.sidebarIsOpen) {
        this.sidebarIsOpen = false;
      }
    }, 1000);
  }

  scrollToOurServices() {
    this.scroll.scrollToElementById('OurServices');
    setTimeout(() => {
      if (this.sidebarIsOpen) {
        this.sidebarIsOpen = false;
      }
    }, 1000);
  }

  scrollToScheduleCall() {
    this.scroll.scrollToElementById('ScheduleCall');
    setTimeout(() => {
      if (this.sidebarIsOpen) {
        this.sidebarIsOpen = false;
      }
    }, 1000);
  }

  scrollToContactUs() {
    this.scroll.scrollToElementById('ContactUs');
    setTimeout(() => {
      if (this.sidebarIsOpen) {
        this.sidebarIsOpen = false;
      }
    }, 1000);
  }

  goToAdmin() {
    this.router.navigate(['admin'])
  }

  goToClients() {
    this.router.navigate(['clients'])
  }

  goToManagement() {
    this.router.navigate(['management'])
  }

  goToLoginPage() {
    this.router.navigate(['login']);
  }

  openSidebar() {
    console.log('opening');
    this.sidebarIsOpen = true;
    console.log('opened');
  }


  menuButtonEventsResponse(id: string) {
    switch(id) {
      case 'Home': {this.scrollToHome(); break;}
      case 'Us': {this.scrollToUs(); break;}
      case 'OurServices': {this.scrollToOurServices(); break;}
      case 'ScheduleCall': {this.scrollToScheduleCall(); break;}
      case 'ContactUs': {this.scrollToContactUs(); break;}
      case 'Sidebar': {this.openSidebar(); break;}
    }
  }
 }

