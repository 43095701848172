import { Component, Output, EventEmitter, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  @Input() page: string = '';
  instagramLogo = faInstagram;
  logStatus: any;
  innerWidth: number = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }
  @Output() menuButtonEvents = new EventEmitter<string>();

  constructor(private router: Router, public auth: AngularFireAuth ) {
    auth.authState.subscribe({
      next: (isLogged: any) => {
        this.logStatus = isLogged;
      }
    });
  }

  openSidebar() {
    this.menuButtonEvents.emit('Sidebar');
  }

  scrollToHome() {
    this.menuButtonEvents.emit('Home');
  }

  scrollToUs() {
    this.menuButtonEvents.emit('Us');
  }

  scrollToOurServices() {
    this.menuButtonEvents.emit('OurServices');
  }

  scrollToScheduleCall() {
    this.menuButtonEvents.emit('ScheduleCall');
  }

  scrollToContactUs() {
    this.menuButtonEvents.emit('ContactUs');
  }

  goToAdmin() {
    this.router.navigate(['admin'])
  }

  goToClients() {
    this.router.navigate(['clients'])
  }

  goToManagement() {
    this.router.navigate(['management'])
  }

  goToLoginPage() {
    this.router.navigate(['login']);
  }

  logout() {
    this.auth.signOut();
    this.router.navigate(['']);
  }
}
