<mat-sidenav-container class="sidebar" [hasBackdrop]="true">
  <mat-sidenav #sidenav mode="over" [(opened)]="sidebarIsOpen" class="sidebar-content">
    <div class="row sidebar-menu-list" *ngIf="currentPage === '/home'">
      <div class="col-12 ms-2" (click)="scrollToHome()">
          <h3 class="menu-buttons" > INICIO </h3>
      </div>
      <div class="col-12 ms-2" (click)="scrollToUs()" >
          <h3 class="menu-buttons" > QUIENES SOMOS </h3>
      </div>
      <div class="col-12 ms-2" (click)="scrollToOurServices()">
          <h3 class="menu-buttons" > NUESTROS SERVICIOS </h3>
      </div>
      <div class="col-12 ms-2" (click)="scrollToScheduleCall()">
          <h3 class="menu-buttons" > AGENDAR EVALUACION </h3>
      </div>
      <div class="col-12 ms-2" (click)="scrollToContactUs()">
          <h3 class="menu-buttons" > CONTACTATE CON NOSOTROS </h3>
      </div>
      <div class="col-12 ms-2 d-flex justify-content-center align-items-center">
          <fa-icon [icon]="instagramIcon" class="instagram-logo d-none"></fa-icon>
      </div>
    </div>
    <div class="row sidebar-menu-list" *ngIf="currentPage !== '/home'">
      <div class="col-12 ms-2" (click)="scrollToHome()">
          <h3 class="menu-buttons" > INICIO </h3>
      </div>
      <div class="col-12 ms-2" (click)="scrollToUs()" >
          <h3 class="menu-buttons" > CLIENTES </h3>
      </div>
      <div class="col-12 ms-2" (click)="scrollToOurServices()">
          <h3 class="menu-buttons" > GESTIÓN </h3>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="bg-transparent">
    <app-header *ngIf="currentPage !== '/login'" [page]="currentPage" (menuButtonEvents)="menuButtonEventsResponse($event)"></app-header>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
