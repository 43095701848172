import { Component, HostListener } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import Swal from 'sweetalert2'
import { PageRouterService } from '../services/page-router.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  googleLogo = faGoogle;
  innerWidth: number = window.innerWidth;
  logInFormGroup = new FormGroup({
    email: new FormControl(null, [Validators.email, Validators.required]),
    password: new FormControl(null, [Validators.required])
  });
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  constructor(public auth: AngularFireAuth, private router: Router, private pageRouter: PageRouterService) {
    this.pageRouter.currentPage('/login');
  }

  loginWithGoogle() {
    this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(() => {
      this.router.navigate(['admin']);
    }).catch((error) => {
      // The provider's account email, can be used in case of
      // auth/account-exists-with-different-credential to fetch the providers
      // linked to the email:
      var email = error.email;
      // The provider's credential:
      var credential = error.credential;
      // In case of auth/account-exists-with-different-credential error,
      // you can fetch the providers using this:
      if (error.code === 'auth/account-exists-with-different-credential') {
        this.auth.fetchSignInMethodsForEmail(email).then(function(providers) {
          // The returned 'providers' is a list of the available providers
          // linked to the email address. Please refer to the guide for a more
          // complete explanation on how to recover from this error.
          Swal.fire({
            title: 'Error',
            text: `Su correo esta siendo usando en los siguientes proveedores: ${providers} `,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            heightAuto: false
          });
        });
      }
    });
  }

  logingWithMail() {
    const email = this.logInFormGroup.get('email')?.value;
    const password = this.logInFormGroup.get('password')?.value;
    if (email && password) {
      this.auth.signInWithEmailAndPassword(email,password).then(() => {
        this.router.navigate(['admin']);
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === 'auth/wrong-password') {
          Swal.fire({
            title: 'Error',
            text: 'Contraseña incorrecta',
            icon: 'error',
            confirmButtonText: 'Cerrar',
            heightAuto: false
          });
        } else if (errorCode === 'auth/user-not-found') {
          Swal.fire({
            title: 'Error',
            text: 'No se encuentra ningun usuario con las credenciales ingresadas, por favor contacte a un administrador.',
            icon: 'error',
            confirmButtonText: 'Cerrar',
            heightAuto: false
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            heightAuto: false
          });
        }
      });
    }
  }

  goToHome() {
    this.router.navigate(['']);
  }
}
