import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class FirebaseService {
  constructor(private firestore: AngularFirestore) {}

  getCollectionObservable(collection: string): Observable<any> {
    const observable = this.firestore.collection(collection).valueChanges();
    return observable;
  }

  addDocument(collection: string, data: any): Promise<DocumentReference<unknown>> {
    const promise = this.firestore.collection(collection).add(data);
    return promise;
  }

  updateDocument(collection: string, document: string, data: any): Promise<void> {
    const promise = this.firestore.collection(collection).doc(document).set(data, {
      merge: true
    });
    return promise;
  }

  deleteDocument(collection: string, document: string): Promise<void> {
    const promise = this.firestore.collection(collection).doc(document).delete();
    return promise;
  }
}
