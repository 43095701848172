<mat-toolbar class="align-items-center header" *ngIf="page !== '/'">

    <!-- Desktop View -->
    <div class="row w-100 d-flex align-items-center" *ngIf="innerWidth >= 1440 && page ==='/home'">
        <div class="col menu-logo-interaction" (click)="scrollToHome()">
            <h1 class="menu-logo"> JC Ingeniería </h1><img src="../../assets/logo/Logo.png" width="50">
        </div>
        <div class="col text-center" (click)="scrollToHome()">
            <h3 class="menu-buttons" > INICIO </h3>
        </div>
        <div class="col text-center" (click)="scrollToUs()" >
            <h3 class="menu-buttons" > QUIENES SOMOS </h3>
        </div>
        <div class="col text-center" (click)="scrollToOurServices()">
            <h3 class="menu-buttons" > NUESTROS SERVICIOS </h3>
        </div>
        <div class="col text-center" (click)="scrollToScheduleCall()">
            <h3 class="menu-buttons" > AGENDAR EVALUACION </h3>
        </div>
        <div class="col text-center" (click)="scrollToContactUs()">
            <h3 class="menu-buttons" > CONTACTATE CON NOSOTROS </h3>
        </div>
        <div class="col d-flex justify-content-center align-items-center">
            <fa-icon [icon]="instagramLogo" class="instagram-logo d-none"></fa-icon>
        </div>
        <button mat-icon-button *ngIf="!logStatus" class="menu-buttons d-flex justify-content-center align-items-center" (click)="goToLoginPage()">
            <fa-icon [icon]="['fas', 'right-to-bracket']"></fa-icon>
        </button>

        <button mat-icon-button *ngIf="logStatus" class="menu-buttons d-flex justify-content-center align-items-center" (click)="logout()">
          <fa-icon [icon]="['fas', 'right-from-bracket']"> logout </fa-icon>
        </button>
    </div>

    <!-- Mobile View -->
    <div class="d-flex justify-content-between align-items-center mx-3" style="width: 100vw" *ngIf="innerWidth < 1440 && page ==='/home'">
      <div class="menu-logo-interaction d-flex" (click)="scrollToHome()">
          <h1 class="menu-logo"> JC Ingeniería </h1><img src="../../assets/logo/Logo.png" [width]="innerWidth > 600 ? 50 : 30" [height]="innerWidth > 600 ? 50 : 30">
      </div>
      <button mat-icon-button (click)="openSidebar()" class="menu-buttons d-flex justify-content-center align-items-center">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>
    </div>

    <!-- Desktop View -->
    <div class="row w-100 align-items-center justify-content-between" *ngIf="innerWidth >= 1440 && page !=='/home'">
      <div class="col-2 menu-logo-interaction" (click)="scrollToHome()">
          <h1 class="menu-logo"> JC Ingeniería </h1><img src="../../assets/logo/Logo.png" width="50">
      </div>
      <div class="col text-center" (click)="goToAdmin()">
        <h3 class="menu-buttons" > INICIO </h3>
      </div>
      <div class="col text-center" (click)="goToClients()">
        <h3 class="menu-buttons" > CLIENTES </h3>
      </div>
      <div class="col text-center" (click)="goToManagement()">
        <h3 class="menu-buttons" > GESTION </h3>
      </div>
      <button mat-icon-button class="menu-buttons d-flex justify-content-center align-items-center" (click)="logout()">
        <fa-icon [icon]="['fas', 'right-from-bracket']"> logout </fa-icon>
      </button>
  </div>

  <!-- Mobile View -->
  <div class="d-flex justify-content-between align-items-center mx-3" style="width: 100vw" *ngIf="innerWidth < 1440 && page !=='/home'">
      <div class="menu-logo-interaction d-flex" (click)="scrollToHome()">
          <h1 class="menu-logo"> JC Ingeniería </h1><img src="../../assets/logo/Logo.png" [width]="innerWidth > 600 ? 50 : 30" [height]="innerWidth > 600 ? 50 : 30">
      </div>
      <button mat-icon-button (click)="openSidebar()" class="menu-buttons d-flex justify-content-center align-items-center">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>
  </div>


</mat-toolbar>
<div class="header-block"></div>
