<div class="container-fluid d-flex justify-content-center align-items-center">
  <mat-card [ngClass]="{'w-25': innerWidth > 1440, 'w-50': innerWidth <= 1440, 'w-75': innerWidth <= 768, 'ms-auto me-auto mat-elevation-z8': true}">
    <button mat-icon-button color="primary" class="mt-3 mx-3" (click)="goToHome()">
      <fa-icon [icon]="['fas', 'arrow-left']" class="arrow-icon me-3"></fa-icon>
    </button>
    <mat-card-header class="pt-0 text-center me-auto ms-auto">
      <mat-card-title>Ingreso Administración</mat-card-title>
      <mat-card-subtitle class="mt-3"> Bienvenido </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="mt-3 text-center">
      <form [formGroup]="logInFormGroup">
        <mat-form-field class="w-100">
          <mat-label>Correo</mat-label>
          <input matInput type="email" formControlName="email" placeholder="Ej. correo@electronico.com">
          <mat-error *ngIf="logInFormGroup.get('email')?.hasError('email') && !logInFormGroup.get('email')?.hasError('required')">
            Ingrese un correo valido.
          </mat-error>
          <mat-error *ngIf="logInFormGroup.get('email')?.hasError('required')">
            El correo es <strong>necesario.</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Contraseña</mat-label>
          <input matInput type="password" formControlName="password">
          <mat-error *ngIf="logInFormGroup.get('clientEmail')?.hasError('required')">
            Ingrese su contraseña
          </mat-error>
        </mat-form-field>
      </form>
      <button mat-raised-button color="primary" class="w-100" [disabled]="logInFormGroup.invalid" (click)="logingWithMail()">
        <fa-icon [icon]="['fas', 'envelope']" class="mail-icon me-3"></fa-icon> Ingresar con correo
      </button>
      <button mat-stroked-button color="primary" class="w-100 mt-3" (click)="loginWithGoogle()">
        <fa-icon [icon]="googleLogo" class="google-icon me-3"></fa-icon> Ingresar con Google
      </button>
    </mat-card-content>
  </mat-card>
</div>
